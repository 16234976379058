import React from 'react';
import AddWriter from '../components/auth/witer';
import AddSaleman from '../components/auth/saleman';
import { Row, Col, Tabs } from 'antd';
const SignUp = () => {
  return(
    <div className='signup-page'>
      <Row align='center'>
        <Col span={23} md={15}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Add Writer" key="1">
              <AddWriter />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Add Sale Agent" key="2">
              <AddSaleman />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
  </div>


  )


};
export default SignUp;