
import { Table } from "antd";
import { Link } from "react-router-dom";



export function SalesTable({data}) {
    const saleColumn = [
        {
            title: 'S.No',
            dataIndex: 'key',
            width: 150,
            render: (value, item, index) => (index + 1)
        },
        {
            title: 'Sudo Name',
            dataIndex: 'sudoname',
            width: 150,
            render: (value, {id, user_role}, index) => (<Link to={{
                    pathname: "/sales/",
                    }} key={1} className="sales-link" state={{ agent_id : id, sudoname : value}}>{user_role === 'admin' ? 'admin' : value}</Link>)
        },
        {
            title: 'User Role',
            dataIndex: 'user_role',
            width: 150,
            render: (value, item, index) => (value)
        },
        {
            title: 'Target',
            dataIndex: 'target',
            width: 150,
            render: (value, item, index) => (<>&pound;{value ? value : 0}</>)
        },
        {
            title: 'Sale',
            dataIndex: 'sale',
            width: 150,
            render: (value, item, index) => (<>&pound;{value}</>)
        },
        {
            title: 'Pending',
            dataIndex: 'pending',
            width: 150,
            render: (value, item, index) => (<>&pound;{value}</>)
        },
        {
            title: 'Remaining Target',
            dataIndex: 'pending',
            width: 150,
            render: (value, item, index) => (<>&pound;{item.user_role !== 'admin' ? item.target - item.sale : 0}</>)
        },
    ]
    return (
        <>
            <Table
                columns={saleColumn}
                dataSource={data}
                pagination={{
                    pageSize: 4,
                  }}
                  scroll={{
                    y: 200
                  }}
            />

        </>
    )

}

export function WriterTable({data}) {
    const writerColumns = [
        {
            title: 'S.No',
            dataIndex: 'key',
            width: 150,
            render: (value, item, index) => (index + 1)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: 150,
            render: (value, item, index) => (value ? value : 'Not Found')
        },
        {
            title: 'User Role',
            dataIndex: 'user_role',
            width: 150,
            render: (value, item, index) => (value)
        },
        {
            title: 'Daily Word',
            dataIndex: 'daily_word_count',
            width: 150,
            render: (value, item, index) => (value ? value : 'Not Found')
        },
        {
            title: 'Delivered Word',
            dataIndex: 'delivered_word',
            width: 150,
            render: (value, item, index) => (value)
        },
    ]
    return (
        <>
            <Table
                columns={writerColumns}
                dataSource={data}
                pagination={{
                    pageSize: 4,
                  }}
                  scroll={{
                    y: 200
                  }}
            />

        </>
    )

}