import React from 'react';
import {Space, Spin } from 'antd';
import './style.css'

const AppLoader = (props) => (
  <Space direction="vertical" style={{ width: '100%' }} className="style-loader">
    <Space>
      <Spin {...props}>
        <div className="content" />
      </Spin>
    </Space>
  </Space>
);

export default AppLoader;