import { Navigate } from "react-router-dom";
import { LoginInfo } from "./lognInfo";

const AdminProtection = ({ children }) => {

    const { user_role } = LoginInfo('all')
    if (user_role === 'admin') {
      return <Navigate to="/home" replace />;
    }

  return children ;
};

const SaleProtection = ({children}) =>{
    const { user_role } = LoginInfo('all')
    if (user_role === 'pcm' || user_role === 'writer' ) {
      return <Navigate to="/home" replace />;
    }
  return children;

}

const ProtectedRoute = ({ children }) => {
  if (!localStorage.getItem('encreden')) {
    return <Navigate to="/sign-in" replace />;
  }

  return children;
};


export {AdminProtection, SaleProtection, ProtectedRoute } ;