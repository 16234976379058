import { Dropdown} from 'antd';
import DropdownImage from '../../assets/images/dropdown.png';
import {Menu} from 'antd';


const MultipleDropdown = ({items}) => (
  <Dropdown overlay={items} trigger={["click"]}>
     <img src={DropdownImage} alt="dropdown" height={20} style={{cursor:'pointer'}} />
  </Dropdown>
);
export default MultipleDropdown;