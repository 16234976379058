import {
    Row,
    Col,
    Form,
    Card,
    message
} from "antd";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputIcon from "../forms/inputIcon";
import FormButton from "../forms/button";
import InputPassword from '../forms/inputPassword';
import InputConfirmPassword from '../forms/inputConfirmPassword';
import CustomSelectBox from "../forms/selectBox";
import axios from "axios";
import {api} from '../../api/index';
import { useEffect } from "react";


const writerrole = [
    {
        label : "PCM",
        value : "pcm"
    },
    {
        label : "Writer",
        value : "writer"
    },
]



export default function AddWriter({employee_id}) {
    const [form] = Form.useForm();
    const Navigate = useNavigate();
    const [blockSubmit, setBlockSubmit] = useState(false)
    const [profileId, setProfileId] = useState(0)

    const newWriter = (value) =>{
        setBlockSubmit(true)
        let url= '';
        if(employee_id){
            url=`${api}/writers/update.php`;
            value = {...value ,  user_id : employee_id, profile_id:profileId}
        }
        else{
            url=`${api}/users/addWriter.php`;
        }
        
        axios.post(url, JSON.stringify(value))
        .then(res =>{
            if(res.data.status){
                message.success(res.data.message)
                form.resetFields();
                setTimeout(() => {
                    Navigate('/employee')
                }, 1500);
                setBlockSubmit(false)
            }
            else{
                setBlockSubmit(false)
                message.error(res.data.message)
            }
        })
        .catch(e => message.error('Something Went Wrong'))

    }

    useEffect(()=>{
        if(employee_id){
            axios.get(`${api}/writers/index.php?writer_id=${employee_id}`)
            .then(res => {
                if(res.data.status){
                    setProfileId(res.data.data[0].profile_id)
                    form.setFieldsValue({
                        name : res.data.data[0].name,
                        email : res.data.data[0].email,
                        daily_word_count : res.data.data[0].daily_word_count,
                        user_role : res.data.data[0].user_role,
                    })
            }
        })
        .catch(()=> message.error('Failed to get info'))
        }
    },[])
    return (
        <Card bordered={false} className="criclebox cardbody h-full style-card create-order-form">
            <div className='create-form-inner'>
                <h2 className="heading">{employee_id ? 'Update Writer' : 'Add Writer'}</h2>
                <Form
                    form={form}
                    name="createorder"
                    className='brand-form'
                    initialValues={{ }}
                    onFinish={newWriter}
                >
                    <Row align="center">

                        <Col span={24}  className="input-div">
                            <InputIcon name='name' message='Writer Name' type="string" />
                        </Col>
                        <Col span={24} className="input-div">
                            <InputIcon name='email' message='Writer Email' type="email" />
                        </Col>
                        <Col span={24} className="input-div">
                            <InputIcon name='daily_word_count' message='Daily Word Count' type="string" />
                        </Col>
                        <Col span={24} className="input-div">
                            <CustomSelectBox name='user_role' message='Writer role is required' options={writerrole} />
                        </Col>
                        {!employee_id && <>
                            <Col span={24} className="input-div">
                                <InputPassword name='password' message='Password' type="string" />
                            </Col>
                            <Col span={24} className="input-div">
                                <InputConfirmPassword name='confirm_password' message='confirm Password' type="string" />
                            </Col>
                        </>
                            }
                        <Col span={8} >
                            <FormButton value="Submit" htmlType="submit" disabled={blockSubmit} />
                        </Col>
                    </Row>

                </Form>
            </div>
        </Card>
    )
}