import {
    Row,
    Col,
    Form,
    Card,
    Input
} from "antd";


// component
import { useState, useEffect } from "react";
import InputIcon from "../components/forms/inputIcon";
import FormButton from "../components/forms/button";
import CustomTextArea from "../components/forms/textArea";
import CustomInputNumber from "../components/forms/inputNumber";
import { message } from "antd";
import { Select } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { amountCurrency } from "../utils/currencyList";
import { createOrder, updateCompleteOrder } from "../store/action/order";
import CustomSelectBox from "../components/forms/selectBox";
import { LoginInfo } from "../hook/lognInfo";
import UseIsMounted from "../hook/mounted";
import { api } from "../api";
import axios from "axios";





const typeOfPaper = [
    {
        label: "Assignment",
        value: "assignment"
    },
    {
        label: "Essay",
        value: "essay"
    },
    {
        label: "Dissertation",
        value: "dissertation"
    },
    {
        label: "Thesis",
        value: "thesis"
    },
    {
        label: "Coursework",
        value: "coursework"
    },
    {
        label: "Researchpaper Help",
        value: "peaserch-paper-help"
    },
    {
        label: "Online Exam",
        value: "online-exam"
    },
    {
        label: "Personal statement",
        value: "personal-statement"
    },
    {
        label: "Others",
        value: "others"
    },
]

const orderTypeList = [
    {
        label: "Word Count",
        value: "word-count"
    },
    {
        label: "Technical + Word Count ",
        value: "technical-word-count "
    },
    {
        label: "Technical",
        value: "technical"
    }
]


const levelStudies = [
    {
        label: "Intermediate ",
        value: "intermediate"
    },
    {
        label: "Bachelor",
        value: "bachelor"
    },
    {
        label: "Masters",
        value: "masters"
    },
    {
        label: "ph.D",
        value: "ph.D"
    },
]

const lang_style = [
    {
        label: "US/Eng",
        value: "us/eng"
    },
    {
        label: "UK/Eng",
        value: "uk/eng"
    },
    {
        label: "AUS/Eng",
        value: "aus/eng"
    },
]










export default function CreateOrder() {
    const Navigate = useNavigate()
    const location = useLocation();

    let order_id  = location.state && location.state.order_id ? location.state.order_id : null;
    let lead_id  = location.state && location.state.lead_id ? location.state.lead_id : null;


    const [form] = Form.useForm();
    const isMounted = UseIsMounted();
    const [customerData, setCustomerData] = useState([])
    const [orderData, setOrderData] = useState([])
    const [orderType, setOrderType] = useState('')
    const profile = LoginInfo('all')
    const [blockSubmit, setBlockSubmit] = useState(false)






    useEffect(() => {
        function order_info() {
            if (lead_id) {
                axios.get(`${api}/leads/index.php?id=${lead_id}`)
                    .then(res => isMounted.current && setCustomerData(...[res.data.data]))
            }
            if (order_id) {
                axios.get(`${api}/orders/index.php?id=${order_id}`)
                    .then(res => isMounted.current && setOrderData(...[res.data.data]))
            }
        }
        order_info()



    }, [])


    if (isMounted.current) {
        if (customerData && customerData.length > 0) {
            form.setFieldsValue({
                customer_name: customerData[0].name,
                customer_email: customerData[0].email,
                customer_phone: customerData[0].phone,
            });
        }

        if (orderData && orderData.length > 0) {
            form.setFieldsValue({
                pages: orderData && orderData.length > 0 ? orderData[0].word_count / 250 : '',
                word_count: orderData && orderData.length > 0 ? orderData[0].word_count : '',
                type_of_paper: orderData && orderData.length > 0 ? orderData[0].type_of_paper : '',
                subject_area: orderData && orderData.length > 0 ? orderData[0].subject_area : '',
                level_of_studies: orderData && orderData.length > 0 ? orderData[0].level_of_studies : '',
                no_of_refrences: orderData && orderData.length > 0 ? orderData[0].no_of_refrences : '',
                amount: orderData && orderData.length > 0 ? orderData[0].amount : '',
                refrence_style: orderData && orderData.length > 0 ? orderData[0].refrence_style : '',
                paper_topic: orderData && orderData.length > 0 ? orderData[0].paper_topic : '',
                lang_style: orderData && orderData.length > 0 ? orderData[0].lang_style : '',
                comment: orderData && orderData.length > 0 ? orderData[0].comment : '',
                default_currency_amount: orderData && orderData.length > 0 ? orderData[0].default_currency_amount : '',
                amount_currency : orderData && orderData.length > 0 ? orderData[0].amount_currency : ''
            })
        }
    }


    const calculateWord = (e) => {
        form.setFieldsValue({
            pages: e.target.value / 250
        });
    }

    const handleChange = (value) => {
        setOrderType(value)
    }



    const createorder = (value) => {
        setBlockSubmit(true)
        if (order_id) {
            value = { ...value, agent_name: profile['username'], order_id: order_id, labelOrderId: orderData[0].order_id }
            updateCompleteOrder(value)
                .then(res => {
                    if (res.status) {
                        setBlockSubmit(false)
                        message.success(res.message)
                        Navigate('/orders')
                    }
                    else {
                        setBlockSubmit(false)
                        message.error(res.message)
                    }
                })
        }
        else {
            value = { ...value, brand_code: customerData[0].brand_code, agent: profile['user_id'], lead_id: customerData[0].id, agent_name: profile['username'] }
            createOrder(value)
                .then(res => {
                    if (res.status) {
                        setBlockSubmit(false)
                        message.success(res.message)
                        Navigate('/orders')
                    }
                    else {
                        setBlockSubmit(false)
                        message.error(res.message)
                    }
                })
        }



    }
    return (
        <Card bordered={false} className="criclebox cardbody style-card create-order-form">
            <div className='create-form-inner'>
                <h2 className="heading">Create Order</h2>
                {
                    customerData.length > 0 ?
                        <Form
                            form={form}
                            name="createorder"
                            className='brand-form'
                            initialValues={{}}
                            onFinish={createorder}
                        >
                            <Row align="center">

                                <Col span={24} md={12} className="input-div">
                                    <InputIcon name='customer_name' message='Customer Name' type="string" />
                                </Col>
                                <Col span={24} md={12} className="input-div">
                                    <InputIcon name='customer_email' message='Customer Email' type="email" />
                                </Col>
                                <Col span={24} md={12} className="input-div">
                                    <InputIcon name='customer_phone' message='Customer Phone' type="string" />
                                </Col>
                                <Col span={24} md={12}>
                                    <div className="d-flex order-row" style={{ alignItems: 'start' }}>
                                        <div className="input-div w-100">
                                            <Select
                                                defaultValue="word-count"
                                                style={{
                                                    width: 180,
                                                }}
                                                onChange={handleChange}
                                                options={orderTypeList}
                                                className="custom-select only-select"
                                            />
                                        </div>
                                        {orderType !== 'technical' &&
                                            <>
                                                <div className="input-div w-100">
                                                    <Form.Item
                                                        name="word_count"
                                                        className='custom-input'
                                                    >
                                                        <Input placeholder="Word Count" onChange={(e) => calculateWord(e)} />
                                                    </Form.Item>
                                                </div>
                                                <div className="input-div w-100">
                                                    <Form.Item
                                                        name="pages"
                                                        className='custom-input'
                                                        required={order_id ? false : true}
                                                    >
                                                        <Input name='pages' message='pages' type="string" disabled={true} />
                                                    </Form.Item>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </Col>


                                <Col span={24} md={12}>
                                    <div className="input-div">
                                        <CustomSelectBox name='type_of_paper' message='Type Of Paper' options={typeOfPaper} />
                                    </div>
                                    <div className="input-div">
                                        <InputIcon name='subject_area' message='Subjective Area' type="string" />
                                    </div>
                                    <div className="input-div">
                                        <CustomSelectBox name='level_of_studies' message='Level Studies' options={levelStudies} />
                                    </div>
                                    <div className="input-div">
                                        <InputIcon name='refrence_style' message='Refrence Style' type="string" />
                                    </div>
                                </Col>

                                <Col span={24} md={12}>
                                    <div className="d-flex order-row">
                                        <div className="input-div w-100">
                                            <CustomSelectBox name='amount_currency' message='Currency' options={amountCurrency} />
                                        </div>
                                        <div className="input-div w-100">
                                            <CustomInputNumber name='amount' message='Amount' min={1} type="string" />
                                        </div>
                                        <div className="input-div w-100">
                                            <CustomInputNumber name='default_currency_amount' min={0} message='Amount GBP' type="string" />
                                        </div>
                                    </div>
                                    <div className="input-div">
                                        <InputIcon name='no_of_refrences' message='No Of Refrences' type="string" />
                                    </div>
                                    <div className="input-div">
                                        <InputIcon name='paper_topic' message='Paper Topic' type="string" />
                                    </div>
                                    <div className="input-div">
                                        <CustomSelectBox name='lang_style' message='Language Style' options={lang_style} />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="input-div">
                                        <CustomTextArea
                                            name='comment'
                                            message="Comment"
                                            autoSize={{ minRows: 8 }}
                                        />
                                    </div>
                                </Col>




                                <Col span={12} md={4} >
                                    <FormButton value={order_id && order_id ? 'Update Order' : "Create Order"} disabled={blockSubmit}  btntype="submit" />
                                </Col>
                            </Row>

                        </Form>
                        :
                        <div>Form Is Loading</div>
                }

            </div>
        </Card>
    )
}