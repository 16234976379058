import React from 'react';
import { useLocation } from 'react-router-dom';
import AddWriter from '../components/auth/witer';
import AddSaleman from '../components/auth/saleman';
import AdminForm from '../components/auth/admin';
import { Row, Col } from 'antd';
const EditEmployee = () => {
    const Location = useLocation();
    const data = Location.state;
    if (data && data.user_role === 'salesman' || data.user_role === 'sales_manager') {
        return (
            <div className='signup-page edit-signup' key={1}>
                <Row align='center'>
                    <Col span={15}>
                    <AddSaleman employee_id={data.employee_id}  />
                    </Col>
                </Row>

            </div>
        )
    }
    else if (data && data.user_role === 'writer' || data.user_role === 'pcm') {
        return (
            <div className='signup-page edit-signup' key={2}>
                <Row align='center'>
                    <Col span={15}>

                    <AddWriter employee_id={data.employee_id} />
                    </Col>
                </Row>

            </div>
        )

    }
    else if (data && data.user_role === 'admin') {
        return (
            <div className='signup-page edit-signup' key={3}>
                <Row align='center'>
                    <Col span={12}>
                        <AdminForm employee_id={data.employee_id} />
                    </Col>
                </Row>

            </div>
        )

    }

};
export default EditEmployee;