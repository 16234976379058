import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  Typography

} from "antd";

import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { all_data } from "../store/action/payment";
import CustomRangeDatePicker from "../components/forms/rangeDatepicker";
import { LoginInfo } from "../hook/lognInfo";


// search input
import { SearchOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import Highlighter from 'react-highlight-words'






function Payment() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user_id = LoginInfo('user_id')
  const payment = useSelector(state => state.payment.data, shallowEqual)
  const [monthDataAllow, setMonthDataAllow] = useState(false)

  // table filter
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  // table filter


  let data = location.state

  // table code start
  const columns = [
    {
      title: 'S.No',
      key: "key",
      dataIndex: 'key',
      width: 150,
      render: (value, item, index) => (index + 1)
    },
    {
      title: "Currency",
      key: "currency",
      dataIndex: "currency",
      width: 200,
      ...getColumnSearchProps('currency')
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      width: 200,
    },
    {
      title: "GBP Amount",
      key: "gbp_amount",
      dataIndex: "gbp_amount",
      width: 200,
      render: (value) => (<>&pound; {value}</>)
    },
  ];

  // project table start
  const project = [
    {
      title: 'S.No',
      key: "currency",
      dataIndex: 'key',
      width: 150,
      render: (value, item, index) => (index + 1)
    },
    {
      title: "Order Id",
      key: "order_id",
      dataIndex: "order_id",
      width: 200,
    },
    {
      title: "Agent",
      key: "agent",
      dataIndex: "agent",
      width: 200,
    },
    {
      title: "Currency",
      key: "currency",
      dataIndex: "currency",
      width: 200,
      ...getColumnSearchProps('currency')
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      width: 200,
    },
    {
      title: "GBP Amount",
      key: "gbp_amount",
      dataIndex: "gbp_amount",
      width: 200,
      render: (value) => (<>&pound; {value}</>)
    },
    {
      title: "Payment Info",
      key: "paymentInfo",
      dataIndex: "paymentInfo",
      render: (value) => {
        if (value.includes('bankName')) {
          value = JSON.stringify(value);
          value = JSON.parse(value);

          return (
            <p>{value}</p>
          )
        }
        else {
          return (
            <p>{value}</p>
          )
        }
      }
    },
    {
      title: "Payment Type",
      key: "paymentType",
      dataIndex: "paymentType",
      width: 200,
      ...getColumnSearchProps('paymentType')
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 200,
    },
    {
      title: "Time",
      key: "time",
      dataIndex: "time",
      width: 200,
      render : (value) => (new Date(value).toDateString())
    },
  ];


  const searchData = (value) => {
    let { leadsRange } = value;
    let postdata = { ...data, startDate: leadsRange[0]._d, endDate: leadsRange[1]._d,  agent_id : location.state && location.state.agent_id ?  location.state.agent_id : user_id}
     setMonthDataAllow(true)
     dispatch(all_data(postdata))
  }

  const showCurrentMonth = () => {
    dispatch(all_data(data))
    setMonthDataAllow(false)

  }

  useEffect(() => {
    if (!monthDataAllow) {
      dispatch(all_data(data))
    }
  }, [dispatch, data])


  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={data && data.sudoname ? 'Currency Wise Total Sale ' + data.sudoname : 'Currency Wise Total Sale'}
              extra={
                <div className="d-flex align-items-center">
                  <div className="ant-filtertabs">
                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra action-head">
                      <Button onClick={showCurrentMonth}>Current Month Sale</Button>
                      <Form
                        name="searchData"
                        className='lead-form'
                        initialValues={{}}
                        onFinish={searchData}
                      >
                        <CustomRangeDatePicker name="leadsRange" message="date is required" />
                        <Button htmlType="submit" type="primary">search</Button>
                      </Form>
                    </div>

                  </div>
                </div>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={payment && payment.monthSaleSum}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>

            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={data && data.sudoname ? "Transaction History " + data.sudoname : "Transaction History"}
              extra={
                <>
                  <p>{payment && payment.time && payment.time}</p>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={project}
                  dataSource={payment && payment.monthSale}
                  pagination={{
                    pageSize: 8,
                  }}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Payment;
