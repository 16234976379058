import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Badge,
  Dropdown,
  List,
  Avatar,
  Menu,
  message,
} from "antd";

import { LoginInfo } from "../../hook/lognInfo";
import axios from "axios";

import { NotificationOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";






function Header() {
  const Navigate = useNavigate();
  const [menu, setMenu] = useState()
  const [notiCount, setNotiCount] = useState()
  const user = LoginInfo('all');


  const playSound = () => {
    const audio = new Audio('https://cms.zemryk.com/play.mpeg');
    audio.play();
  };

  const checkLead = async () => {
    try {
      const notiData = await axios.get(`${api}/leads/index.php?checkLead=${true}`)
      if (notiData.data.status) {
        playSound()
      }
    }
    catch (err) { console.log(err) }
  }

  const removeNotification = () =>{
    axios.get(`${api}/notification/deleteAll.php?to_emp=${Number(user && user['user_id'])}`)
    .then(res => {
      message.error(res.data.message)
      NotificationList()
    })
  }


  const NotificationList = async () => {
    await axios.get(`${api}/notification/index.php?to_emp=${Number(user &&user['user_id'])}`)
      .then(res => {
        if (res.data.status) {
          setNotiCount(res.data.data.length)
          setMenu(
            <List
              min-width="100%"
              className="header-notifications-dropdown "
              itemLayout="horizontal"
              header={res.data.data.length > 0 && <a href="#" onClick={removeNotification} className="dropdown-remove">Clear All</a> }
              dataSource={res.data.data}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.title}
                    description={(
                      <div className="noti-cont">
                        <p>{item.comment}</p>
                        <span>{new Date(item.time).toDateString()}</span>
                      </div>

                    )}
                  />
                </List.Item>



              )}
            />
          );
        }
      })
      .catch(err => { console.log(err) })
  }

  useEffect(() => {
    const saleinterval = setInterval(() => {
      NotificationList()
      checkLead()
    }, 4000)
    
    const writerInterval = setInterval(() => {
      NotificationList()
    }, 4000)

    if (user && user['user_role'] && user['user_role'].includes('sale') || user['user_role'] === 'admin') {
      checkLead()
      NotificationList()
      return () => clearInterval(saleinterval)
    }
    else {
      NotificationList()
      return () => clearInterval(writerInterval)
    }


  }, [])


  const logOut = () => {
    localStorage.removeItem('encreden')
    message.success('Logout !')
    setTimeout(() => {
      Navigate('/sign-in')
    }, 1000);

  }


  const profileMenu = (
    <Menu className="style-menu">
      <Menu.Item onClick={logOut}>Log Out</Menu.Item>
    </Menu>

  );


  return (
    <>
      <Row gutter={[24, 0]} align="middle">
        <Col span={12} md={6}>
          <h2 className="mb-0 text-white text-capitalize">Welcome Back, {user && user['username']}</h2>
        </Col>
        <Col span={12} md={18} className="header-control">
          <Dropdown overlay={profileMenu} trigger={["click"]} >
                 <Avatar className="profile text-uppercase">{user && user['username'].substring(0, 1)}</Avatar>
          </Dropdown>
          <Badge size="small" count={notiCount}>
            <Dropdown overlay={menu} trigger={["click"]}>
                <a
                href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <NotificationOutlined style={{ fontSize: "22px" }} />
              </a>
            
            </Dropdown>
          </Badge>
        </Col>
      </Row>
    </>
  );
}

export default Header;
