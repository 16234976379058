import { Drawer} from 'antd';
import './style.css'
const StyleDrawer = (props) => {

  return (
    <>
      <Drawer
       open={props.open}
       {...props}
      >
        {props.children}
      </Drawer>
    </>
  );
};
export default StyleDrawer;