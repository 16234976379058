import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
} from "antd";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { ExportOutlined } from "@ant-design/icons";

import axios from "axios";
import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";
import { WriterTable, SalesTable } from "../components/userTable";
import { LoginInfo } from "../hook/lognInfo";
import { api } from "../api";




function Home() {
  const { Title} = Typography;

  const [data, setData] = useState()
  const [allCharts, setAllCharts] = useState(false)


  const loginInfo = LoginInfo('all')

  const [salesChart, setSaleChart] = useState({
    sale: null,
    cat: null,
  })


 





  useEffect(() => {
    if (loginInfo) {
      gettingAllData()
    }
    function gettingAllData() {
      let saleNumber = [];
      let saleCat = [];
      let postData = {
         user_role : loginInfo['role'],
         user_id : loginInfo['user_id'],
      }
      axios.post(`${api}/dashboard/index.php`, JSON.stringify(postData))
        .then(res => {
          setData(...[res.data])
        }).then(() => {
          if(data && data.sales && data.sales.stats.length > 0){
            data.sales.stats.map((val, key) => {
              saleNumber.push(val.sale)
              saleCat.push(val.month)
            })
            setSaleChart({
              sale: saleNumber,
              cat: saleCat
            });
          }
          setAllCharts(true)
        })

    }

  }, [allCharts])











  if (loginInfo['role'] === 'admin') {
    return (
      <>
        <div className="layout-content">
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={10} xl={10} className="mb-24">
              <Row gutter={[24, 0]}>
                <Col xs={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <div className="dashabord-box dashabord-box-row">
                      <div>
                        <h5 className="support-text">Today Sales</h5>
                        <h3 className="medium-heading">{data && data.sales && data.sales.todaySale.toLocaleString()}</h3>
                      </div>
                      <span className="payment-icon">&pound;</span>
                    </div>
                  </Card>
                </Col>
              </Row>
              <h3 className="medium-heading">Month Sales</h3>
              <Row gutter={[24, 0]}>
                {data && data.sales && data.sales.monthSale.length > 0 && data.sales.monthSale.map((val, key) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24" key={key}>
                    <Card bordered={false} className="criclebox h-full">
                      <div className="dashabord-box dashabord-box-row">
                        <div>
                          <h5 className={val.status + " support-text text-uppercase"}>{val.status}</h5>
                          <h3 className="medium-heading">{val.sale.toLocaleString()}</h3>
                        </div>
                        <span className="payment-icon">&pound;</span>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>

              <h3 className="medium-heading">Leads Sales</h3>
              <Row gutter={[24, 0]}>
                {data && data.sales && data.sales.leadSale && data.sales.leadSale.length > 0 && data.sales.leadSale.map((val, key) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24" key={key}>
                    <Card bordered={false} className="criclebox h-full">
                      <div className="dashabord-box dashabord-box-row">
                        <div>
                          <h5 className={val.status + " support-text text-uppercase green-color"}>{val.status}</h5>
                          <h3 className="medium-heading">{val.sale.toLocaleString()}</h3>
                        </div>
                        <span className="payment-icon">&pound;</span>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>

            </Col>
            <Col xs={24} sm={24} md={12} lg={14} xl={14} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                {data && data.sales && data.sales.stats.length > 0 ?
                  <>
                    {allCharts ?
                      <Echart {...salesChart} />
                      : 'Sales is Loading'
                    }
                  </>
                  : 'No Stats To Preview'
                }

              </Card>
            </Col>
          </Row>
          {/* Leads */}
          <Row gutter={[24, 0]}>
            {/* Leads Table */}
            <Col xs={24} sm={24} md={12} lg={14} xl={14} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="linechart">
                  <div>
                    <Title level={5}>Leads Stats </Title>
                  </div>
                  <div className="sales">
                    <ul>
                      <li>{<MinusOutlined />} Total Leads</li>
                      <li>{<MinusOutlined />} Converted Leads</li>
                    </ul>
                  </div>
                </div>
                {data && data.leads && data.leads.leadStats ?
                  <LineChart data={data.leads.leadStats} />
                  : 'Lead Chart is Loading'
                }

              </Card>
            </Col>
            {/* Leads */}
            <Col xs={24} sm={24} md={12} lg={10} xl={10} className="mb-24">
              <Row gutter={[24, 0]}>
                {/* Today Leads */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <h3>Today Leads</h3>
                    {data && data.leads && data.leads.todayLead.length > 0 ?
                      data.leads.todayLead.map((c, key) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          className="mb-24"
                          key={key}
                        >
                          <Card bordered={false} className={"criclebox " + c.status}>
                            <div className="number">
                              <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                  <Title level={3}>
                                    {c.count} <small >({c.status.replace(/-/g, ' ')})</small>
                                  </Title>
                                </Col>
                                <Col xs={6}>
                                  <div className="icon-box"><ExportOutlined /></div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                      ))
                      : <span>No Records To Preview</span>
                    }
                  </Card>
                </Col>
                {/* Month Leads */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <h3>This Month Leads</h3>
                    <Row className="rowgap-vbox" gutter={[24, 0]}>
                      {data && data.leads && data.leads.monthLead.length > 0 ?
                        data.leads.monthLead.map((c, key) => (
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            className="mb-24"
                            key={key}
                          >
                            <Card bordered={false} className={"criclebox " + c.status}>
                              <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                  <Col xs={18}>
                                    <Title level={3}>
                                      {c.count} <small >({c.status.replace(/-/g, ' ')})</small>
                                    </Title>
                                  </Col>
                                  <Col xs={6}>
                                    <div className="icon-box"><ExportOutlined /></div>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </Col>
                        ))
                        : <span>No Records To Preview</span>
                      }
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Orders */}
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={10} xl={10} className="mb-24">
              <Row gutter={[24, 0]}>
                {/* Today Orders */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <h3>Today Orders</h3>
                    {data && data.orders && data.orders.todayOrder.length > 0 ?
                      data.orders.todayOrder.map((c, key) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          className="mb-24"
                          key={key}
                        >
                          <Card bordered={false} className={"criclebox  " + c.status}>
                            <div className="number">
                              <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                  <span className="text-capitalize title">{c.status && c.status.replace(/-/g, ' ')}</span>
                                  <Title level={3}>
                                    {c.orders} <small className={c.status}>({c.count})</small>
                                  </Title>
                                </Col>
                                <Col xs={6}>
                                  <div className="icon-box"><ExportOutlined /></div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>

                      ))

                      : <span>No Records To Preview</span>
                    }
                  </Card>
                </Col>
                {/* month order stats */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <h3>This Month Orders</h3>
                    <Row className="rowgap-vbox" gutter={[24, 0]}>
                      {data && data.orders && data.orders.monthOrder.length > 0 ?
                        data.orders.monthOrder.map((c, key) => (
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            className="mb-24"
                            key={key}
                          >
                            <Card bordered={false} className={"criclebox  " + c.status}>
                              <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                  <Col xs={18}>
                                    <span className="text-capitalize title">{c.status && c.status.replace(/-/g, ' ')}</span>
                                    <Title level={3}>
                                      {c.orders} <small className={c.status}>({c.count})</small>
                                    </Title>
                                  </Col>
                                  <Col xs={6}>
                                    <div className="icon-box"><ExportOutlined /></div>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </Col>

                        ))
                        : <span>No Records To Preview</span>
                      }
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={14} xl={14} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="linechart">
                  <div>
                    <Title level={5}>Orders Stats </Title>
                  </div>
                  <div className="sales">
                    <ul>
                      <li>{<MinusOutlined />} Delivered Orders</li>
                      <li>{<MinusOutlined />} Refunded Orders</li>
                    </ul>
                  </div>
                </div>
                {data && data.orders.orderStats ?
                  <LineChart data={data.orders.orderStats} />
                  :
                  'No Orders To Preview'
                }


              </Card>
            </Col>
          </Row>
          {/* Status */}
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <h3>Writer Status</h3>
                    {
                    data && data.employeeStatus && data.employeeStatus.salesStatus.length > 0 ? 
                    <WriterTable data={data.employeeStatus.writerStatus} />
                    : 'No Status To Preview'
                    }
                </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <h3>Sales Status</h3>
                  {
                    data && data.employeeStatus && data.employeeStatus.salesStatus.length > 0 ? 
                    <SalesTable data={data.employeeStatus.salesStatus} />
                    : 'No Status To Preview'
                    }
                </Card>
            </Col>
          </Row>
        </div>
      </>
    );

  }
  if (loginInfo['role'] === 'writer' || loginInfo['role'] === 'pcm' ) {
    return (
      <>
        <div className="layout-content">
          {/* Orders */}
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={10} xl={10} className="mb-24">
              <Row gutter={[24, 0]}>
                {/* Today Orders */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <h3>Today Orders</h3>
                    {data && data.orders && data.orders.todayOrder.length > 0 ?
                      data.orders.todayOrder.map((c, key) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          className="mb-24"
                          key={key}
                        >
                          <Card bordered={false} className={"criclebox  " + c.status}>
                            <div className="number">
                              <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                  <span className="text-capitalize title">{c.status && c.status.replace(/-/g, ' ')}</span>
                                  <Title level={3}>
                                    {c.orders} <small className={c.status}>({c.count})</small>
                                  </Title>
                                </Col>
                                <Col xs={6}>
                                  <div className="icon-box"><ExportOutlined /></div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>

                      ))

                      : <span>No Records To Preview</span>
                    }
                  </Card>
                </Col>
                {/* month order stats */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <h3>This Month Orders</h3>
                    <Row className="rowgap-vbox" gutter={[24, 0]}>
                      {data && data.orders && data.orders.monthOrder.length > 0 ?
                        data.orders.monthOrder.map((c, key) => (
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            className="mb-24"
                            key={key}
                          >
                            <Card bordered={false} className={"criclebox  " + c.status}>
                              <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                  <Col xs={18}>
                                    <span className="text-capitalize title">{c.status && c.status.replace(/-/g, ' ')}</span>
                                    <Title level={3}>
                                      {c.orders} <small className={c.status}>({c.count})</small>
                                    </Title>
                                  </Col>
                                  <Col xs={6}>
                                    <div className="icon-box"><ExportOutlined /></div>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </Col>

                        ))
                        : <span>No Records To Preview</span>
                      }
                    </Row>
                  </Card>
                </Col>
                <Col xs={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <div className="dashabord-box dashabord-box-row">
                      <div>
                        <h5 className="support-text">Total Word Count</h5>
                        <h3 className="medium-heading mb-0">{data && data.orders && data.orders.total}</h3>
                      </div>
                      <div>
                        <h5 className="support-text">Acheive Word Count</h5>
                        <h3 className="medium-heading mb-0">{data && data.orders && data.orders.paid.toLocaleString()}</h3>
                      </div>
                      <div>
                        <h5 className="support-text">Remaining Word Count</h5>
                        <h3 className="medium-heading mb-0">{loginInfo && data && data.orders.total - Number(data.orders.paid)}</h3>
                      </div>
        
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={14} xl={14} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="linechart">
                  <div>
                    <Title level={5}>Orders Stats </Title>
                  </div>
                  <div className="sales">
                    <ul>
                      <li>{<MinusOutlined />} Delivered Orders</li>
                      <li>{<MinusOutlined />} Refunded Orders</li>
                    </ul>
                  </div>
                </div>
                {data && data.orders.orderStats ?
                  <LineChart data={data.orders.orderStats} />
                  :
                  'No Orders To Preview'
                }


              </Card>
            </Col>
          </Row>
        </div>
      </>
    );

  }

  if (loginInfo['role'] === 'salesman' || loginInfo['role'] === 'sales_manager' ) {
    return (
      <>
        <div className="layout-content">
          {/* salesman */}
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={10} xl={10} className="mb-24">
              <Row gutter={[24, 0]}>
                <Col xs={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <div className="dashabord-box dashabord-box-row">
                      <div>
                        <h5 className="support-text">Today Sales</h5>
                        <h3 className="medium-heading">{data && data.sales && data.sales.todaySale.toLocaleString()}</h3>
                      </div>
                      <span className="payment-icon">&pound;</span>
                    </div>
                  </Card>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <h3>Latest Create Account</h3>
                    {data && data.userList && data.userList.length > 0 ?
                      <DashboardUserList orders={data.userList} />
                      : <span>No Records To Preview</span>
                    }
                  </Card>
                </Col> */}
              </Row>
              <h3 className="medium-heading">Month Sales</h3>
              <Row gutter={[24, 0]}>
                {data && data.sales && data.sales.monthSale.length > 0 && data.sales.monthSale.map((val, key) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24" key={key}>
                    <Card bordered={false} className="criclebox h-full">
                      <div className="dashabord-box dashabord-box-row">
                        <div>
                          <h5 className={val.status + " support-text text-uppercase"}>{val.status}</h5>
                          <h3 className="medium-heading mt-3">{val.sale.toLocaleString()}</h3>
                        </div>
                        <span className="payment-icon">&pound;</span>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Col xs={24} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <div className="dashabord-box dashabord-box-row">
                      <div>
                        <h5 className="support-text">Total Target</h5>
                        <h3 className="medium-heading mb-0">{loginInfo && loginInfo['target'].toLocaleString()}</h3>
                      </div>
                      <div>
                        <h5 className="support-text">Acheive Target</h5>
                        <h3 className="medium-heading mb-0">{data && data.sales && data.sales.paid.toLocaleString()}</h3>
                      </div>
                      <div>
                        <h5 className="support-text">Remaining Target</h5>
                        <h3 className="medium-heading mb-0">{loginInfo && data && data.sales && Number(loginInfo['target']) - Number(data.sales.paid)}</h3>
                      </div>
        
                    </div>
                  </Card>
                </Col>
            </Col>
            <Col xs={24} sm={24} md={12} lg={14} xl={14} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
              {data && data.sales && data.sales.stats.length > 0 ?
                  <>
                    {allCharts ?
                      <Echart {...salesChart} />
                      : 'Sales is Loading'
                    }
                  </>
                  : <p className="text-center">No Stats To Preview </p>
                }


              </Card>
            </Col>
          </Row>
        </div>
      </>
    );

  }
}

export default Home;
