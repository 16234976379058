import { Form , DatePicker } from "antd";

export default function CustomDatePicker(props) {
    return (
        <Form.Item
            name={props.name}
            className='custom-input'
            rules={[
                {
                    required: true,
                    type : props.type,
                    message: props.message,
                },
            ]}
        >
            <DatePicker placeholder={props.message} />
        </Form.Item>
    )
}