import {
    Row,
    Col,
    Form,
    Card,
    message
} from "antd";

import { useState } from "react";
import InputIcon from "../forms/inputIcon";
import FormButton from "../forms/button";
import CustomInputNumber from '../forms/inputNumber';
import CustomSelectBox from "../forms/selectBox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {api} from '../../api';
import InputPassword from '../forms/inputPassword';
import InputConfirmPassword from '../forms/inputConfirmPassword';


const salesrole = [
    {
        label : "Sales Manager",
        value : "sales_manager"
    },
    {
        label : "Sales Agent",
        value : "salesman"
    },
]




export default function AddSaleman({employee_id}) {
    const Navigate = useNavigate()

    const [form] = Form.useForm();
    const [blockSubmit, setBlockSubmit] = useState(false)
    const [profileId, setProfileId] = useState(0)

    const newSalesman = (value) =>{
        setBlockSubmit(true)
        let url= '';
        if(employee_id){
            url=`${api}/salesman/update.php`;
            value = {...value ,  user_id : employee_id, profile_id:profileId}
        }
        else{
            url=`${api}/users/addSalesman.php`;
        }
        axios.post(url, JSON.stringify(value))
        .then(res =>{
            console.log(res)
            if(res.data.status){
                message.success(res.data.message)
                form.resetFields();
                setTimeout(() => {
                    Navigate('/employee')
                }, 1500);
                setBlockSubmit(false)
            }
            else{
                setBlockSubmit(false)
                message.error(res.data.message)
            }
        })
        .catch(e => message.error('Something Went Wrong'))
    }


    useEffect(()=>{
        if(employee_id){
            axios.get(`${api}/salesman/index.php?agent_id=${employee_id}`)
            .then(res => {
                console.log(res)
                if(res.data.status){
                    setProfileId(res.data.data[0].profile_id)
                    form.setFieldsValue({
                        sudo_name : res.data.data[0].sudo_name,
                        sudo_email : res.data.data[0].sudo_email,
                        target : res.data.data[0].target,
                        user_role : res.data.data[0].user_role,
                        phone : res.data.data[0].phone,

                    })
            }
        })
        .catch(()=> message.error('Failed to get info'))
        }
    },[])

    return (
        <Card bordered={false} className="criclebox cardbody h-full style-card create-order-form">
            <div className='create-form-inner'>
                <h2 className="heading">{employee_id ? 'Update Agent' : 'Add Sale Agent'}</h2>
                <Form
                    form={form}
                    name="createorder"
                    className='brand-form'
                    initialValues={{ }}
                    onFinish={newSalesman}
                >
                    <Row align="center">

                        <Col span={24}  className="input-div">
                            <InputIcon name='sudo_name' message='Sudo Name' type="string" />
                        </Col>
                        <Col span={24} className="input-div">
                            <InputIcon name='sudo_email' message='Sudo Email' type="email" />
                        </Col>
                        <Col span={24} className="input-div">
                            <InputIcon name='phone' message='Phone' type="string" />
                        </Col>
                        <Col span={24} className="input-div">
                            <CustomInputNumber name='target' message='Target' type="string" />
                        </Col>
                        <Col span={24} className="input-div">
                            <CustomSelectBox name='user_role' message='Sale agent role is required' options={salesrole} />
                        </Col>
                        {!employee_id && <>
                            <Col span={24} className="input-div">
                                <InputPassword name='password' message='Password' type="string" />
                            </Col>
                            <Col span={24} className="input-div">
                                <InputConfirmPassword name='confirm_password' message='confirm Password' type="string" />
                            </Col>
                        </>
                            }
                        <Col span={8} >
                            <FormButton value="Submit" btntype="submit" disabled={blockSubmit} />
                        </Col>
                    </Row>

                </Form>
            </div>
        </Card>
    )
}