
export const amountCurrency =[
    {
        label: "GBP",
        value: "GBP"
    },
    {
        label: "AUD",
        value: "AUD"
    },
    {
        label: "EURO",
        value: "EUR"
    },
    {
        label: "AED",
        value: "AED"
    },
    {
        label: "USD",
        value: "USD"
    },
    {
        label: "CAD",
        value: "CAD"
    },
    {
        label: "SAR",
        value: "SAR"
    },
]