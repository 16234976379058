import { useEffect } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Menu,
    message
  } from "antd";
  import { Link } from "react-router-dom";
  import { PlusCircleOutlined } from '@ant-design/icons';

  // component
  import StyleDropdown from "../components/forms/dropdown";
  import { useDispatch, useSelector, shallowEqual } from "react-redux";
  import { all_data } from "../store/action/employee";
  import { updateEmployee } from "../store/action/employee";
  import { useNavigate } from "react-router-dom";
  import StyleTable from "../components/table";
  // table code start



 
  
  function Employee() {
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const employee = useSelector(state => state.employee.data, shallowEqual)

    
    //brandId
    const updateEmployeeStatus = (id, status) =>{
      let value = {id : id, status : status}
      updateEmployee(value)
      .then(res =>{
        if(res && res.status){
            dispatch(all_data())
            message.success(res.message)
        }
        else{
            message.error(res.message)
        }
      }).catch(()=> message.error('Something went wrong'))
    
    }


    useEffect(()=>{
      dispatch(all_data())
    },[dispatch])

    const editEmployee = (id, user_role)=>{
      let state = {employee_id:id, user_role: user_role}
      Navigate('/edit-employee',{state:state})
    }



    const columns = [
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        width: "200",
      },
      {
        title: "user_role",
        dataIndex: "user_role",
        width: "150",
      },
    
      {
        title: "Created time",
        dataIndex: "time",
        key: "time",
        width: "150",
        render : (value) =>(<>{new Date(value).toDateString()}</>)
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: "200",
        render : (value) =>{
          let status = value && Number(value) === 1 ? 'active' : 'deactive'
          return(
          <p className={status+'-btn status'}>{status}</p>
          )
          
        }
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        width: "100",
        render : (value, {id, user_role}) =>{
          const menu = (
            <Menu>
              <Menu.Item key={1}  onClick={()=>updateEmployeeStatus(id, true)}>Activate</Menu.Item>
              <Menu.Item key={2}  onClick={()=>updateEmployeeStatus(id, 'false')}>Deactivate</Menu.Item>
              <Menu.Item key={3}  onClick={()=>editEmployee(id, user_role)}>Edit Employee</Menu.Item>
            </Menu>
          );
          return(
            <StyleDropdown menu={menu} />
          )
         
        }
      },
    ];

    let propsData = {
      columns : columns,
      data : employee,
      pageSize :  8,
      height : 500,

    }


    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24 employee-card"
                title="Employee"
                extra={
                  <div className="button-box">
                    <Link to="/sign-up"><Button icon={<PlusCircleOutlined />} className="icon-button">Add Employee</Button></Link> 
                    {/* <Radio.Group onChange={onChange} defaultValue="a">
                      <Radio.Button value="all">All</Radio.Button>
                      <Radio.Button value="active">Active</Radio.Button>
                    </Radio.Group> */}
                  </div>
                }
              >
  
                  {employee && employee.length > 0 &&
                  <StyleTable {...propsData} />
  }
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
  export default Employee;
  