import {
    Row,
    Col,
    Form,
    Card,
    Button,
    message,
    Tabs,
} from "antd";
import { PaperClipOutlined } from '@ant-design/icons';


// component
import { useEffect, useState } from "react";

import InputIcon from "../components/forms/inputIcon";
import FormButton from "../components/forms/button";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { all_data } from "../store/action/lead";
import axios from "axios";
import { api } from "../api";
import CopyToClipboard from "react-copy-to-clipboard";
import { LoginInfo } from '../hook/lognInfo'





export default function OrderPayment() {
    const dispatch = useDispatch()
    const location = useLocation();
    const [customerData, setCustomerData] = useState()
    const [paymentLink, setPaymentLink] = useState()
    const [paymentType, setPaymentType] = useState(1)
    const [blockSubmit, setBlockSubmit] = useState(false)
    const [link] = Form.useForm();
    const [bank] = Form.useForm();
    const [manual] = Form.useForm();

    //order id
    let { id } = useParams();
    let { lead_id } = location.state

    const user_id = LoginInfo('user_id')


    const leads = useSelector(state => state.leads.data, shallowEqual)


    useEffect(() => {
        dispatch(all_data())
        //getting lead id
        if (leads && leads.length > 0) {
            setCustomerData(leads.all.filter(val => val.id === lead_id))
        }
    }, [dispatch])



    const copyLink = () => {
        let copyButton = document.getElementById('copy-btn')
        copyButton.innerHTML = "copied";
        copyButton.classList.add('active')
        setTimeout(() => {
            copyButton.classList.remove('active')
        }, 3000)

    }



    const createorder = (value) => {
        setBlockSubmit(true)

        let paymentTypes = '';
        let paymentInfo = '';

        if (Number(paymentType) === 1) {  //payment type and info
            paymentTypes = 'stripe';
            paymentInfo = 'stripe-link'
        }
        else if (Number(paymentType) === 2) {  //payment type and info
            paymentTypes = 'bank-transfer';
            paymentInfo = value.bankName + ', ' + value.acc_title + ', ' + value.acc_num ;
        }
        else if (Number(paymentType) === 3) {  //payment type and info
            paymentTypes = 'stripe-manually';
            paymentInfo = 'Stripe Manually';
        }
        //postData to send

        value = { ...value, order_id: id, lead_id: lead_id, paymentType: paymentTypes, paymentInfo: paymentInfo, user_id: user_id }
        console.log(value)
        axios.post(`${api}/payment/index.php`, JSON.stringify(value))
            .then(res => {
                if (res.data.status) {
                    message.success(res.data.message);
                    bank.resetFields();
                    manual.resetFields();
                    link.resetFields();
                    if (res.data.data) {
                        setBlockSubmit(false)
                        setPaymentLink(res && res.data && res.data.data.payment_update)
                    }
                }
                else {
                    setBlockSubmit(false)
                    message.error(res.data.message)
                }

            })
    }

    const changePaymentType = (val) => {
        if(Number(val) === 1){
            bank.resetFields();
            manual.resetFields();

        }
        if(Number(val) === 2){
            link.resetFields();
            manual.resetFields();
        }
        if(Number(val) === 3){
            link.resetFields();
            bank.resetFields();

        }
        setPaymentType(val)
    }


    return (
        <>
            <Tabs defaultActiveKey="1" onChange={(val) => changePaymentType(val)}>
                <Tabs.TabPane tab="Stripe Link" key="1" >
                    <Card bordered={false} className="criclebox cardbody h-full style-card create-order-form">
                        <Row >
                            <Col span={12} xl={12} md={24}>
                                <div className='create-form-inner payment-form' style={{ padding: "30px 32px 6px" }}>
                                    <h2 className="heading">{customerData && customerData.length > 0 && customerData[0].customer_name} (Payment Link)</h2>
                                    <Form
                                        key={1}
                                        form={link}
                                        name="stripe-form"
                                        className='brand-form'
                                        initialValues={{}}
                                        onFinish={createorder}
                                    >
                                        <Row align="center">
                                             <Col span={24} md={24}>
                                                <div className="mr-5">
                                                    <InputIcon name='url' message='type :yes' type="string" />
                                                </div>
                                            </Col>
                                            <Col span={24} md={12}>
                                                <div className="mr-5">
                                                    <InputIcon name='paying' message='Paying Amount' type="string" />
                                                </div>
                                            </Col>
                                            <Col span={24} md={12}>
                                                <div className="ml-5">
                                                    <InputIcon name='gbp_amount' message='Amount In GBP' type="string" />
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ marginTop: 0 }} >
                                                <FormButton value="Generate Order Link" btntype="submit" disabled={blockSubmit} />
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>

                            <Col span={12} xl={12} md={24}>
                                <div className='create-form-inner payment-form'>
                                    <h2 className="heading">Payment Link</h2>
                                    {paymentLink ?
                                        <div className="paymentlink-container">
                                            <p style={{width : '100%', overflow : 'auto'}}>{paymentLink}</p>
                                            <CopyToClipboard text={paymentLink}>
                                                <Button onClick={() => copyLink()} id="copy-btn"><PaperClipOutlined /></Button>
                                            </CopyToClipboard>
                                        </div>
                                        :
                                        <p className="message">Payment Link Will generate here</p>
                                    }
                                </div>
                            </Col>

                        </Row>
                    </Card>

                </Tabs.TabPane>
                <Tabs.TabPane tab="Bank Transfer" key="2">
                    <Card bordered={false} className="criclebox cardbody h-full style-card create-order-form">
                        <Row align="center">
                            <Col span={12} xl={12} md={24}>
                                <div className='create-form-inner payment-form' style={{ padding: "30px 32px 6px" }}>
                                    <h2 className="heading">{customerData && customerData.length > 0 && customerData[0].customer_name} (Add Bank Transfer)</h2>
                                    <Form
                                        key={2}
                                        name="bank-form"
                                        className='brand-form'
                                        initialValues={{}}
                                        onFinish={createorder}
                                        form={bank}
                                    >
                                        <Row align="center">
                                            <Col span={24} md={12}>
                                                <div className="mr-5">
                                                    <InputIcon name='paying' message='Paying Amount' type="string" />
                                                </div>
                                            </Col>
                                            <Col span={24} md={12}>
                                                <div className="ml-5">
                                                    <InputIcon name='gbp_amount' message='Amount In GBP' type="string" />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <InputIcon name='bankName' message='Bank Name' type="string" />
                                            </Col>
                                            <Col span={24}>
                                                <InputIcon name='acc_title' message='Account Title' type="string" />
                                            </Col>
                                            <Col span={24}>
                                                <InputIcon name='acc_num' message='Account Number' type="string" />
                                            </Col>
                                            <Col span={12} style={{ marginTop: 0 }} >
                                                <FormButton value="Add Transaction" btntype="submit" disabled={blockSubmit} />
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Card>

                </Tabs.TabPane>
                <Tabs.TabPane tab="Stripe Manual" key="3">
                    <Card bordered={false} className="criclebox cardbody h-full style-card create-order-form">
                        <Row align="center">
                            <Col span={12} xl={12} md={24}>
                                <div className='create-form-inner payment-form' style={{ padding: "30px 32px 6px" }}>
                                    <h2 className="heading">{customerData && customerData.length > 0 && customerData[0].customer_name} (Add Stripe Payment)</h2>
                                    <Form
                                        key={1}
                                        name="stripe-form"
                                        className='brand-form'
                                        initialValues={{}}
                                        onFinish={createorder}
                                        form={manual}
                                    >
                                        <Row align="center">
                                            <Col span={24} md={12}>
                                                <div className="mr-5">
                                                    <InputIcon name='paying' message='Paying Amount' type="string" />
                                                </div>
                                            </Col>
                                            <Col span={24} md={12}>
                                                <div className="ml-5">
                                                    <InputIcon name='gbp_amount' message='Amount In GBP' type="string" />
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ marginTop: 0 }} >
                                                <FormButton value="Submit" btntype="submit" disabled={blockSubmit} />
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Tabs.TabPane>
            </Tabs>

        </>

    )
}