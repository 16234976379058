
import axios from 'axios';
import { api } from '../../api'
import { LoginInfo } from '../../hook/lognInfo';


export const GET_DATA = "GET_DATA"
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
export const GET_DATA_FAILURE = 'GET_DATA_FAILURE'

export const get_data = () => ({ type: GET_DATA })
export const get_data_success = (data) => ({ type: GET_DATA_SUCCESS, payload: data, })
export const get_data_failure = () => ({ type: GET_DATA_FAILURE })



export function all_data(params) {
    return async dispatch => {
        dispatch(get_data())
        try {
            let url = null;
            let id = params && params.agent_id;
            if(id){
                url = `${api}/payment/currencyPayment.php?user_id=${id}`;
            }
            else{
                url = `${api}/payment/currencyPayment.php?user_id=${LoginInfo('user_id')}`;
            }
            if(params && params.startDate && params.endDate){
                const res = await axios.post(`${api}/payment/currencyPayment.php`, JSON.stringify(params))
                const data = await res.data.data
                dispatch(get_data_success(data))
            }
            else{
                const res = await axios.get(url)
                const data = await res.data.data
                dispatch(get_data_success(data))
            }
        }
        catch (error) {
            dispatch(get_data_failure())
        }
    }
}






