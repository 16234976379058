
import {ArrowLeftOutlined} from '@ant-design/icons';
import {useNavigate } from 'react-router-dom';

export default function GoBack(){
    const Navigate = useNavigate();
    const goBack = () =>{
        Navigate(-1)
        
    }
    return(
        <ArrowLeftOutlined onClick={()=>goBack()} className="goback-btn" />
    )
}