import { combineReducers } from "redux";
import brandReducer from './brand';
import leadReducer from './leads';
import orderReducer from "./order";
import employeeReducer from "./employee";
import paymentReducer from './payment'

const rootReducers = combineReducers({
    brands : brandReducer,
    leads : leadReducer,
    orders : orderReducer,
    employee : employeeReducer,
    payment : paymentReducer

})

export default rootReducers ;