
import { Row, Col, Card } from "antd";
import AddBrandForm from "../components/addbrand";
import GoBack from "../components/goback";

export default function AddBrand() {
    return (
        <Row gutter={[24, 5]}>
            <Col xs="24" xl={24}>
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                >
                    <Row justify="center">
                        <Col xs="24" xl={16}>
                            <div className="section">
                                <GoBack />
                                <AddBrandForm />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}