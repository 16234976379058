
import axios from 'axios';
import { api } from '../../api'


export const GET_DATA = "GET_DATA"
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
export const GET_DATA_FAILURE = 'GET_DATA_FAILURE'

export const get_data = () => ({ type: GET_DATA })
export const get_data_success = (data) => ({ type: GET_DATA_SUCCESS, payload: data, })
export const get_data_failure = () => ({ type: GET_DATA_FAILURE })




export function all_data() {
    return async dispatch => {
        dispatch(get_data())
        try {
            const res = await axios.get(`${api}/brand/index.php`)
            const data = await res.data.data
            dispatch(get_data_success(data))
        }
        catch (error) {
            dispatch(get_data_failure())
        }
    }
}

export async function AddBrand(values) {
    let res = await axios.post(`${api}/brand/index.php`, values)
    return res.data;
}


export async function updateBrand(values) {
    let res = await axios.post(`${api}/brand/update.php`, values)
    return res.data;
}




