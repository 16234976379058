import { Form , DatePicker } from "antd";

export default function CustomRangeDatePicker(props) {
    const { RangePicker } = DatePicker;
    return (
        <Form.Item
            name={props.name}
            className='rangepicker mb-0'
            rules={[
                {
                    required: true,
                    message: props.message,
                },
            ]}
        >
            <RangePicker dateFormat = 'DD/MM/YYYY' />
        </Form.Item>
    )
}