import ReactApexChart from "react-apexcharts";





function LineChart({data}) {
  return (
    <>
   

      <ReactApexChart
        className="full-width"
        options={{
          chart: {
            width: "100%",
            height: 350,
            type: "area",
            toolbar: {
              show: false,
            },
          },
      
          legend: {
            show: false,
          },
      
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
      
          yaxis: {
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: ["#8c8c8c"],
              },
              formatter: function(val, index) {
                return Math.round(val);
              }
            },
            min: 0,
          },
      
          xaxis: {
            categories: data.category,    
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: [
                  "#8c8c8c",
                  "#8c8c8c",
                  "#8c8c8c",
                  "#8c8c8c",
                  "#8c8c8c",
                  "#8c8c8c",
                  "#8c8c8c",
                  "#8c8c8c",
                  "#8c8c8c",
                ],
              },
            },
          },
      
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          colors: ["#247BA0", "#FF1654"],
        }}
        series={data.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
