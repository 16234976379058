import {
    Row,
    Col,
    Form,
    Card,
    message
} from "antd";

import { useState } from "react";
import FormButton from "../forms/button";
import InputPassword from "../forms/inputPassword";
import InputConfirmPassword from "../forms/inputConfirmPassword";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {api} from '../../api';



export default function AdminForm({employee_id}) {
    const Navigate = useNavigate()

    const [form] = Form.useForm();
    const [blockSubmit, setBlockSubmit] = useState(false)

    const updateAdmin = (value) =>{
        setBlockSubmit(true)
        value={...value, user_id : employee_id }
        axios.post(`${api}/users/updateadmin.php`, JSON.stringify(value))
        .then(res =>{
            if(res.data.status){
                message.success(res.data.message)
                form.resetFields();
                setTimeout(() => {
                    Navigate('/employee')
                }, 1500);
                setBlockSubmit(false)
            }
            else{
                setBlockSubmit(false)
                message.error(res.data.message)
            }
        })
        .catch(e => message.error('Something Went Wrong'))
    }

    return (
        <Card bordered={false} className="criclebox cardbody h-full style-card create-order-form">
            <div className='create-form-inner'>
                <h2 className="heading">Update Admin Password</h2>
                <Form
                    form={form}
                    name="createorder"
                    className='admin-form'
                    initialValues={{ }}
                    onFinish={updateAdmin}
                >
                    <Row align="center">
                        <Col span={24} className="input-div">
                            <InputPassword name='password' message='Password' type="string" />
                        </Col>
                        <Col span={24} className="input-div">
                            <InputConfirmPassword name='confirm_password' message='confirm Password' type="string" />
                        </Col>
                        <Col span={8} >
                            <FormButton value="Update Admin" btntype="submit" disabled={blockSubmit} />
                        </Col>
                    </Row>

                </Form>
            </div>
        </Card>
    )
}