import {Form, Input } from 'antd';
const { TextArea } = Input;

export default function CustomTextArea(props) {
    return (
        <Form.Item
            name={props.name}
            className='custom-input'
            rules={[
                {
                    type : props.type,
                    message: props.message,
                },
            ]}
        >
            <TextArea placeholder={props.message} {...props} />
        </Form.Item>
    )
}