
import { useState, useEffect, useRef } from "react";
import {
    Card,
    Col,
    Row,
    Typography,
    Button,
    message,
    Form,
    Menu
} from "antd";
import { NavLink } from "react-router-dom";
import StyleTable from "../components/table";
import { ExportOutlined } from "@ant-design/icons";
import { all_data, updateLeads } from "../store/action/lead";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import CustomRangeDatePicker from '../components/forms/rangeDatepicker'
import CustomSelectBox from "../components/forms/selectBox";
import FormButton from "../components/forms/button";
import StyleModal from "../components/modal";
import MultipleDropdown from "../components/multidropdown";
import { LoginInfo } from "../hook/lognInfo";
import { monthData } from "../store/action/lead";
import axios from "axios";
import { api } from "../api";

// search input
import { SearchOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import Highlighter from 'react-highlight-words'







function Leads() {
    const dispatch = useDispatch()
    const { Title } = Typography;

    const userRole = LoginInfo('role')
    const userName = LoginInfo('username')
    const profile_id = LoginInfo('profile_id')

    const leads = useSelector(state => state.leads.data, shallowEqual)

    const [showAgentModal, setShowAgentModal] = useState(false)

    const [salemanList, setSalmanList] = useState([])


    // table filter
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    // table filter



    //brandId

    const [brandId, setBrandId] = useState(null)
    const [monthDataAllow, setMonthDataAllow] = useState(false)

    const updateAgent = (values) => {
        values = { ...values, id: brandId, username: userName }
        updateLeads(values)
            .then(res => {
                if (!monthDataAllow) {
                    dispatch(all_data())
                }
                message.success(res.message)
                setShowAgentModal(false)
            })
    }

    const showAgentModalForm = (id) => {
        setBrandId(id)
        setShowAgentModal(true)
        gettingSalesman()
    }

    async function gettingSalesman() {
        axios.get(`${api}/salesman/index.php`)
            .then(res => setSalmanList(...[res.data.data]))
    }


    const changeStatus = (id, status, type) => {
        let propsData = {
            id: id,
            status: status,
            type: type,
            username: userName,
            user_id: profile_id

        }

        updateLeads(propsData)
            .then(res => {
                if (!monthDataAllow) {
                    dispatch(all_data())
                }
                message.success(res.message)

            })
    }



    useEffect(() => {
        if (!monthDataAllow) {
            dispatch(all_data())
        }

    }, [dispatch])

    // search data
    const searchData = (value) => {
        let { leadsRange } = value;
        let serachPost = { startDate: leadsRange[0]._d, endDate: leadsRange[1]._d }
        setMonthDataAllow(true)
        dispatch(monthData(serachPost))
    }

    const showCurrentMonth = () => {
        dispatch(all_data())
        setMonthDataAllow(false)

    }


    const columns = [
        {
            title: 'S.No',
            dataIndex: 'id',
            width: 80,
            render: (value, item, index) => (index + 1 + '')
        },
        {
            title: 'Full Name',
            dataIndex: 'name',
            width: 200,
            ...getColumnSearchProps('name')

        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: 200,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            width: 200,
        },
        {
            title: 'Brand Code',
            dataIndex: 'brand_code',
            width: 200,
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            width: 200,
        },
        {
            title: 'Agent',
            dataIndex: 'agent_name',
            width: 200,
        },
        {
            title: 'Status',
            dataIndex: 'type',
            width: 200,
            ...getColumnSearchProps('type'),
            render: (value) => {
                let leadObj = {
                    'not-converted': 'deactive-btn',
                    'invalid': 'deactive-btn',
                    'PPC': 'active-btn',
                    'organic': 'active-btn',
                    'referal': 'active-btn',
                    'conversion-failed': 'deactive-btn',
                }
                return (
                    <>
                        <p className={'status ' + leadObj[value]}>{value.replace(/-/g, ' ')}</p>
                    </>
                )

            }
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: 220,
            render: (value, { key }) => {
                const adminItem = (
                    <Menu className="style-menu">
                        <Menu.SubMenu title="Converted">
                            <Menu.Item key={11} onClick={() => changeStatus(value, 'converted', 'PPC')}>PPC</Menu.Item>
                            <Menu.Item key={12} onClick={() => changeStatus(value, 'converted', 'organic')}>Organic</Menu.Item>
                            <Menu.Item key={13} onClick={() => changeStatus(value, 'converted', 'referal')}>Referal</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.Item key={14} onClick={() => changeStatus(value, 'in-progress', 'in-progress')}>In Progress</Menu.Item>
                        <Menu.Item key={14} onClick={() => changeStatus(value, 'invalid', 'invalid')}>Invalid</Menu.Item>
                        <Menu.Item key={15} onClick={() => changeStatus(value, 'conversion-failed', 'conversion-failed')}>Converstion Failed</Menu.Item>
                        <Menu.Item key={16} onClick={() => showAgentModalForm(value)}>Update Agent</Menu.Item>
                        <Menu.Item key={17}>
                            <NavLink to={{
                                pathname: "/create_order/",
                            }} state={{ lead_id: value }} >Create Order</NavLink>
                        </Menu.Item>
                    </Menu>
                )
                const saleAgent = (
                    <Menu className="style-menu">
                        <Menu.SubMenu title="Converted">
                            <Menu.Item key={11} onClick={() => changeStatus(value, 'converted', 'PPC')}>PPC</Menu.Item>
                            <Menu.Item key={12} onClick={() => changeStatus(value, 'converted', 'organic')}>Organic</Menu.Item>
                            <Menu.Item key={13} onClick={() => changeStatus(value, 'converted', 'referal')}>Referal</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.Item key={14} onClick={() => changeStatus(value, 'in-progress', 'in-progress')}>In Progress</Menu.Item>
                        <Menu.Item key={14} onClick={() => changeStatus(value, 'invalid', 'invalid')}>Invalid</Menu.Item>
                        <Menu.Item key={15} onClick={() => changeStatus(value, 'conversion-failed', 'conversion-failed')}>Converstion Failed</Menu.Item>
                        <Menu.Item key={17}>
                            <NavLink to={{
                                pathname: "/create_order/",
                            }} state={{ lead_id: value }} >Create Order</NavLink>
                        </Menu.Item>
                    </Menu>
                )
                return (
                    <>
                        {userRole === 'sales_manager' &&
                            <MultipleDropdown items={adminItem} />
                        }
                        {userRole === 'admin' &&
                            <MultipleDropdown items={adminItem} />
                        }
                        {userRole === 'salesman' &&
                            <MultipleDropdown items={saleAgent} />

                        }
                    </>
                )

            }
        },
    ];

    let propsData = {
        columns: columns,
        data: leads && leads.all ? leads.all : [],
        pagination: 50,
        height: 350
    }



    return (
        <>
            <div className="layout-content">
                {/* Month Leads */}
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {
                        leads && leads.monthStats &&
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={6}

                            className="mb-24"
                        >
                            <Card bordered={false} className="criclebox blue-card ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={18}>
                                            <span>{leads.monthStats.date}</span>
                                            <Title level={3}>
                                                {leads.monthStats.total} <small>(Total Leads)</small>
                                            </Title>
                                        </Col>
                                        <Col xs={6}>
                                            <div className="icon-box"><ExportOutlined /></div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    }
                    {
                        leads && leads.monthStats && leads.monthStats.stats.length > 0 && leads.monthStats.stats.map((c, index) => (
                            <Col
                                key={index}
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                                xl={6}

                                className="mb-24"
                            >
                                <Card bordered={false} className={"criclebox " + c.status}>
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col xs={18}>
                                                <span>{leads.monthStats.date}</span>
                                                <Title level={3}>
                                                    {c.count} <small >({c.status.replace(/-/g, ' ')})</small>
                                                </Title>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="icon-box"><ExportOutlined /></div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                </Row>
                {/* Month Leads */}

                {/* Today Leads */}
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {
                        leads && leads.today &&
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={6}

                            className="mb-24"
                        >
                            <Card bordered={false} className="criclebox blue-card">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={18}>
                                            <span>{leads.today.date}</span>
                                            <Title level={3}>
                                                {leads.today.total} <small>(Total Leads)</small>
                                            </Title>
                                        </Col>
                                        <Col xs={6}>
                                            <div className="icon-box"><ExportOutlined /></div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    }
                    {
                        leads && leads.today && leads.today.stats.length > 0 && leads.today.stats.map((c, index) => (
                            <Col
                                key={index}
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                                xl={6}

                                className="mb-24"
                            >
                                <Card bordered={false} className={"criclebox " + c.status}>
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col xs={18}>
                                                <span>{leads.today.date}</span>
                                                <Title level={3}>
                                                    {c.count} <small >({c.status.replace(/-/g, ' ')})</small>
                                                </Title>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="icon-box"><ExportOutlined /></div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                </Row>
                {/* Month Leads */}



                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                        <Card bordered={false} className="criclebox cardbody h-full">
                            <div className="project-ant">
                                <div className="lead-heading">
                                    <Title level={5}>Leads</Title>
                                    <ul>(
                                        {
                                            leads && leads.monthStats && leads.monthStats.tps && leads.monthStats.tps.length > 0 && leads.monthStats.tps.map((val, key)=>(
                                                <li><span>{val.count}</span> <span>{val.type}</span></li>
                                            ))
                                        })
                                    </ul>
                                </div>
                                <div className="ant-filtertabs">
                                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra action-head">
                                        <Button onClick={showCurrentMonth}>Current Month Leads</Button>
                                        <Form
                                            name="searchData"
                                            className='lead-form'
                                            initialValues={{}}
                                            onFinish={searchData}
                                        >
                                            <CustomRangeDatePicker name="leadsRange" message="date is required" />
                                            <Button htmlType="submit" type="primary">search</Button>
                                        </Form>
                                    </div>

                                </div>
                            </div>
                            <div className="ant-list-box table-responsive">
                                <div className="position-relative">
                                    {propsData && propsData.data.length > 0 && <StyleTable {...propsData} />}
                                </div>

                            </div>
                        </Card>
                    </Col>
                </Row>

                {showAgentModal && <StyleModal view={showAgentModal} footer={null} >
                    <div className='create-form-inner modal-form'>
                        <h2 className="heading">Update Sale Agent</h2>
                        <Form
                            name="agentchange"
                            className='brand-form'
                            initialValues={{}}
                            onFinish={updateAgent}
                        >
                            <Row gutter={10}>
                                <Col span={24} className="input-div">
                                    {salemanList && salemanList.length > 0 ?
                                        <CustomSelectBox options={salemanList} message="select Sale Agents" name="agent" />
                                        : 'getting sale agents name'
                                    }
                                </Col>
                                <Col span={12} >
                                    <FormButton value="Change" btntype="submit" />
                                </Col>
                                <Col span={12} >
                                    <FormButton value="Close" btntype="button" onClick={() => setShowAgentModal(false)} />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </StyleModal>
                }
            </div>
        </>
    );
}

export default Leads;
